import containsObjectInArray from '../utils/containsObjectInArray';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default class MenuColetanche {

    static init() {
        new MenuColetanche();
    }

    constructor() {
        this.DOM = {
            menu: document.querySelector('.coletanche .header-app__nav'),
            burger: document.querySelector('.coletanche #header-app__burger-menu'),
            headerUtils: document.querySelector('.coletanche .header-app__utilities'),
            overlay: null
        };
        if (!this.DOM.menu || !this.DOM.burger) {
            return;
        }
        this.submenus = {
            menuActif: 0,
            data: []
        }
        this.modal = this.createModalSubmenu();
        this.insertModalInMenu(this.modal);
        this.bindEvents();
    }

    arrowReturnNav() {
        return /*html*/ `
            <svg xmlns="http://www.w3.org/2000/svg" width="23.806" height="16.61" viewBox="0 0 23.806 16.61">
                <path id="Union_61" data-name="Union 61" d="M15.927,0,14.169,1.855l4.94,5.208H0V9.6H19.055l-4.886,5.151,1.759,1.855L23.806,8.3,15.929,0h0Z" transform="translate(23.806 16.61) rotate(180)" fill="#fff"/>
            </svg>      
        `;
    }

    createModalSubmenu() {
        const modal = document.createElement('div'),
            titleModal = document.createElement('div'),
            titleModalInner = document.createElement('div'),
            titleModalBtn = document.createElement('BUTTON'),
            contentModal = document.createElement('div');
        modal.classList.add('modal-submenu');
        titleModal.classList.add('modal-submenu__title');
        titleModalInner.classList.add('modal-submenu__title-inner');
        titleModalBtn.classList.add('modal-submenu__title-btn');
        titleModalBtn.innerHTML = this.arrowReturnNav();
        contentModal.classList.add('modal-submenu__content');
        modal.appendChild(titleModal);
        modal.appendChild(contentModal);
        titleModal.appendChild(titleModalBtn);
        titleModal.appendChild(titleModalInner);
        return modal;
    }

    openModalSubmenu() {
        if (!this.modal.classList.contains('is-open')) {
            this.modal.classList.add('is-open');
        }
    }

    closeModalSubmenu() {
        if (this.modal.classList.contains('is-open')) {
            this.modal.classList.remove('is-open');
            this.submenus.data = [];
        }
    }

    insertModalInMenu(modal) { 
        this.DOM.menu.appendChild(modal);
    }

    insertSubmenuInModal(submenu) {
        const title = this.modal.querySelector('.modal-submenu__title-inner'),
            content = this.modal.querySelector('.modal-submenu__content');
        title.innerText = submenu.title;
        content.innerHTML = submenu.list.outerHTML;
        content.querySelector('ul').classList.remove('submenu');
    }

    open() {
        if (!this.DOM.menu.classList.contains('is-visible')) {
            this.DOM.headerUtils.style.display = "flex";
            disableBodyScroll(this.DOM.menu);
            this.DOM.menu.classList.add('is-visible');
        }
    }

    close() {
        if (this.DOM.menu.classList.contains('is-visible')) {
            enableBodyScroll(this.DOM.menu);
            this.DOM.menu.classList.remove('is-visible');
            setTimeout(() => this.closeModalSubmenu(), 300);
            this.hideUtilitiesBlock();
        }
    }

    eventsLi(e) {
        // check that the element is a "li" and that it contains the data-attribute "data-order"
        if (e.target && e.target.parentNode.nodeName == "LI" && e.target.parentNode.getAttribute('data-order') && (window.matchMedia("(max-width: 981px)").matches)) {
            // check if the "Li" contains the class "containesSubMenu" before continued
            const li = e.target.parentNode;
            if (!li.classList.contains('containesSubMenu')) {
                return;
            }
            e.preventDefault();
            // creating of data for submenu
            const data = {
                order: +li.getAttribute('data-order'),
                title: li.querySelector('a').innerText,
                list: li.querySelector('.submenu')
            };
            // check if the array "this.submenus" contains the object after clicked
            if (!containsObjectInArray(data, this.submenus.data)) {
                this.submenus.data.push(data);   
            }
            // defines the new menu number actif 
            this.submenus.menuActif = +li.getAttribute('data-order');
            // check if the window width is lower or equal to 980 for hide the block utilities header
            this.hideUtilitiesBlock(this.submenus.menuActif);
            // insert the data in modal
            this.insertSubmenuInModal(data);
            this.openModalSubmenu();
        }
    }

    hideUtilitiesBlock(menuActif = 0) {
        console.log("HIDING");
        if (menuActif > 0) {
            this.DOM.headerUtils.style.display = 'none';
            return;
        }
        this.DOM.headerUtils.style.display = "";
    }
    
    bindEventsOverlay() {
        this.close();
        this.activeBurger();
    }

    activeBurger() {
        if (!this.DOM.burger.classList.contains('is-active')) {
            this.DOM.burger.classList.add('is-active');
            return true;
        }
        this.DOM.burger.classList.remove('is-active');
        return false;
    }

    eventsBtnReturn(e) {
        // check if the HTML element is a button 
        if (e.target && e.target.nodeName === "BUTTON") {
            if (this.submenus.menuActif > 1) {
                this.insertSubmenuInModal(this.submenus.data[(this.submenus.menuActif - 1) - 1]);
                this.submenus.menuActif = this.submenus.menuActif - 1;
                return;
            }
            
            this.closeModalSubmenu(); 
            this.submenus.menuActif = 0;

            if (document.body.getBoundingClientRect().width <= 980) {
                this.hideUtilitiesBlock(this.submenus.menuActif);
            }
        }
    }

    bindEvents() {    
        this.DOM.menu.addEventListener('click', event => {
            this.eventsLi(event);
            this.eventsBtnReturn(event);
        });

        this.DOM.burger.addEventListener('click', () => {
            if (this.activeBurger()) {
                this.open();
                return;
            }
            this.close();
        });
    }
    
}