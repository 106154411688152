import Tabs from '../components/Tabs';

class Contact {

    static init() {
        new Contact();
    }

    constructor() {
        this.initComponents();
    }

    initComponents() {
        Tabs.init();
    }
}

export default {
    init() {
        Contact.init();
    }
}