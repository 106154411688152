import FilterProduct from '../components/FilterProduct';
import Tabs from '../components/Tabs';

class DetailProduct {
    
    static init() {
        new DetailProduct();
    }

    constructor() {
        this.DOM = {
            btnFilter: document.querySelector('.btn-filter-products'),
            sectionProducts: document.querySelector('.blockProducts')
        };
        this.initComponents();
        this.bindEventsGalleryProduct();
        this.displayBtnFilter = this.displayBtnFilter.bind(this);

        if (document.body.clientWidth < 981) {
            this.addEventScrollBtnFilter();
        } else {
            this.removeEventScrollBtnFilter();
        }
        const newLaunch = this.launch();
        window.addEventListener('resize', newLaunch);
    }

    launch() {
        let media = "desktop";
        return () => {
            if (document.body.clientWidth < 981) {
                if (media === "mobile") {
                    this.addEventScrollBtnFilter();
                } else {
                    media = "mobile";
                }

                console.log('media : ', media);
            } else {
                
                if (media === "desktop") {
                    this.removeEventScrollBtnFilter();
                } else {
                    media = "desktop";
                }

                console.log('media : ', media);
            }
        }
    }

    addEventScrollBtnFilter() {
        let scrollY = Math.round(this.DOM.sectionProducts.getBoundingClientRect().top);
        console.log('window.innerHeight : ', window.innerHeight);
        document.addEventListener('scroll', this.displayBtnFilter);
    }

    removeEventScrollBtnFilter() {
        document.removeEventListener('scroll', this.displayBtnFilter);
    }

    displayBtnFilter() {
        console.log('yo');
        /*
        console.log('scrollY : ', scrollY);
            console.log('window.scrollY : ', window.scrollY);
            if (window.scrollY >= scrollY) {
                if (!this.DOM.btnFilter.classList.contains('is-visible')) {
                    this.DOM.btnFilter.classList.add('is-visible');
                }
            } else {
                if (this.DOM.btnFilter.classList.contains('is-visible')) {
                    this.DOM.btnFilter.classList.remove('is-visible');
                }
            }*/

    }

    initComponents () {
        FilterProduct.init();
        Tabs.init();
    }

    bindEventsGalleryProduct() {
        const productsThumbnails = document.querySelector('.product-infos-thumbnails'),
            mediaVedette = document.querySelector('.product-media img'),
            productMedia = document.querySelector('.product-media .product-btn-media'),
            thumbnails = productsThumbnails.querySelectorAll('li');
        
        thumbnails.forEach(thumb => {
            thumb.addEventListener('click', () => {
                for (const thumb of thumbnails) {
                    if (thumb.classList.contains('is-active')) {
                        thumb.classList.remove('is-active');
                    }
                }
                const mediaThumb = thumb.getAttribute('data-thumbnail');
                thumb.classList.add('is-active');
                mediaVedette.setAttribute('src', mediaThumb);
                productMedia.setAttribute('data-img-popup', mediaThumb);
            });
        })
    }
}

export default {
    init() {
        DetailProduct.init();
    }
}