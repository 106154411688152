class NoticeTechnics {
    static init() {
        new NoticeTechnics();
    }

    constructor() {
        this.DOM = {
            blockNotice: document.querySelectorAll('.block-notice')
        };
        this.events();
    }

    events() {
        this.DOM.blockNotice.forEach(block => {
            const title = block.querySelector('.title-notice');
            title.addEventListener('click', () => {
                if (!block.classList.contains('is-active')) {
                    block.classList.add('is-active');
                    return;
                }
                block.classList.remove('is-active');
                this.removeAllBlockSubNotice(block);
            });
        });

        document.querySelectorAll('.block-sub-notice').forEach(block => {
            const title = block.querySelector('.title-sub-notice');
            const content = block.querySelector('.content-sub-notice');
            
            title.addEventListener('click', () => {
                if (!content.classList.contains('is-active')) {
                    title.classList.add('is-active');
                    content.classList.add('is-active');
                    return;
                }
                title.classList.remove('is-active');
                content.classList.remove('is-active');
            });
        });
    }

    removeAllBlockSubNotice(block) {
        block.querySelectorAll('.content-sub-notice').forEach(block => {
            const title = block.previousElementSibling;
            title.classList.remove('is-active');
            block.classList.remove('is-active');
        })
    }
}

export default {
    init() {
        NoticeTechnics.init();
    }
}