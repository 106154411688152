const large = 992;
const medium = 768;
const small = 576;
const extraLarge = 1200;

export const resolutions = {
    large: window.matchMedia(`screen and (max-width: ${large}px)`),
    extraLarge: window.matchMedia(`screen and (max-width: ${extraLarge}px)`),
    medium: window.matchMedia(`screen and (max-width: ${medium}px)`),
    small: window.matchMedia(`screen and (max-width: ${small}px)`)
};

export function detectResolution(x) {
    if (x.matches) {
        return true;
    };
    return false;
}

// Add the callback function as a listener to the query list.
for (const resolution in resolutions) {
    resolutions[resolution].addListener(detectResolution);
}

