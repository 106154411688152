export default class PopupImages {

    constructor(elements) {
        this.elements = document.querySelectorAll(elements);
        if (this.elements.length === 0) {
            return;
        }
    }

    init() {
        this.popup = this._buildPopup();
        this._buildDOM(this.popup);
        this._setStyleBtn();
        this._bindEvents();
    }

    _buildPopup() {
        const popup = document.createElement('div'),
            img = new Image(),
            inner = document.createElement('div'),
            btnClose = document.createElement('button');
        popup.setAttribute('id', 'popup-images');
        popup.classList.add('is-hidden');
        inner.classList.add('popup-images__inner');
        btnClose.classList.add('popup-images__btn-close');
        btnClose.innerText = 'Fermer';
        popup.appendChild(inner);
        inner.appendChild(img);
        inner.appendChild(btnClose);
        return popup;
    }

    _changeImage(path) {
        this.popup.querySelector('img').setAttribute('src', path);
    }

    _closePopup() {
        if (!this.popup.classList.contains('is-hidden')) {
            this.popup.classList.add('is-hidden');
            this.popup.querySelector('img').setAttribute('src', '');
        }
    }

    _openPopup() {
        if (this.popup.classList.contains('is-hidden')) {
            this.popup.classList.remove('is-hidden');
        }
    }

    _setStyleBtn() {
        this.elements.forEach(element => element.style.cursor = 'pointer');
    }

    _buildDOM(popup) {
        document.body.appendChild(popup);
    }

    _bindEvents() {
        this.elements.forEach(element => {
            element.addEventListener('click', () => {
                const dataImgPopup = element.getAttribute('data-img-popup');
                if (dataImgPopup && dataImgPopup !== "") {
                    this._changeImage(dataImgPopup);
                    this._openPopup();
                }
            });
        });
        this.popup.addEventListener('click', this._closePopup.bind(this));
        this.popup.querySelector('.popup-images__btn-close').addEventListener('click', this._closePopup.bind(this));
        this.popup.querySelector('.popup-images__inner').addEventListener('click', e => e.stopPropagation());
    }

}