import { detectResolution, resolutions } from "../utils/detectDevice";

export default class Tabs {
    static init() {
        const tabsComponent = document.querySelectorAll('.tabs-component');
        if (tabsComponent.length === 0) {
            return;
        }
        tabsComponent.forEach(tab => new Tabs(tab));
    }

    constructor(tabComponent) {
        this.DOM = {
            tabsNav: tabComponent.querySelector('.tabs-nav'),
            tabsSection: tabComponent.querySelectorAll('.tab-detail')
        };
        this.DOM.btnTabsMobile = this.DOM.tabsNav.querySelector('.js-tabs-btn-mobile');
        this.init();
    }

    init() {
        this.bindEvents();
    }

    bindEvents() {
        this.DOM.tabsNav.querySelectorAll('li').forEach(tab => {
            tab.addEventListener('click', () => {
                this._removeTabVisible();
                this._removeTabActive();
                tab.classList.add('is-active');
                if (this.DOM.btnTabsMobile.classList.contains('is-open')) {
                    this.DOM.btnTabsMobile.classList.remove('is-open');
                }
                this._displayBlocDetail(tab.getAttribute('data-tab'));
            });
        });
        
        this.DOM.btnTabsMobile.addEventListener('click', (e) => {
            const btn = e.target;
            if (!btn.classList.contains('is-open')) {
                btn.classList.add('is-open');
            } else {
                btn.classList.remove('is-open');
            }
            this._activeTabNavMobile();
        });
    }

    _activeTabNavMobile() {
        this.DOM.tabsNav.querySelectorAll('li').forEach(tab => {
            if (!tab.classList.contains('is-visible')) {
                tab.classList.add('is-visible');
                return;
            }
            tab.classList.remove('is-visible');
        });
    }

    _removeTabVisible() {
        this.DOM.tabsNav.querySelectorAll('li').forEach(tab => tab.classList.remove('is-visible'));
    }

    _removeTabActive() {
        this.DOM.tabsNav.querySelectorAll('li').forEach(tab => tab.classList.remove('is-active'));
    }

    _displayBlocDetail(dataTab) {
        this.DOM.tabsSection.forEach(tabSection => {
            if (tabSection.getAttribute('data-tab') === dataTab) {
                tabSection.classList.add('is-active');
                return;                
            }
            tabSection.classList.remove('is-active');
        });
    }
}