import MenuAxter from '../components/MenuAxter';
import MenuColetanche from '../components/MenuColetanche';
import PopupImages from '../components/PopupImages';
import NavLang from '../components/NavLang';
import detectSite from '../utils/detectSite';

class Common {

    static init() {
        new Common();
    }
    
    constructor() {
        this.initComponents();
    }

    initComponents() {
        if (detectSite('coletanche')) {
            MenuColetanche.init();
        } 
        if (detectSite('axter')) {
            MenuAxter.init();
        }  
        NavLang.init();
        this.PopupImages();
    }

    PopupImages() {
        const popupImages = new PopupImages('.js-img-popup');
        popupImages.init();
    }

}

export default {
    init() {
        Common.init();
    }
}