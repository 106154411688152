import '../styles/index.scss';
import Router from './utils/Router';
import contact from './screens/Contact';
import home from './screens/Home';
import detailProduct from './screens/DetailProduct';
import common from './screens/common';
import noticeTechnics from './screens/NoticeTechnics';

const router = new Router({
    common,
    home,
    contact,
    detailProduct,
    noticeTechnics
});
  
// Load Events
window.addEventListener('DOMContentLoaded', () => {
    router.loadEvents();
});


function submit_post_via_hidden_form(url, params) {
    var f = $("<form target='_blank' method='POST' style='display:none;'></form>").attr({
        action: url
    }).appendTo(document.body);

    for (var i in params) {
        if (params.hasOwnProperty(i)) {
            $('<input type="hidden" />').attr({
                name: i,
                value: params[i]
            }).appendTo(f);
        }
    }

    f.submit();

    f.remove();
}