export default class NavLang {

    static init() {
        new NavLang();
    }

    constructor() {
        this.DOM = {
            btn: document.querySelector('.js-btn-lang-choice'),
            listLang: document.querySelector('.js-list-lang-choice')
        };
        if (this.DOM.btn === null && this.DOM.listLang === null) {
            return;
        }
        this.events();
    }

    events() {
        this.DOM.btn.addEventListener('click', () => {
            if (!this.DOM.btn.classList.contains('is-active')) {
                this.DOM.btn.classList.add('is-active');
                this.DOM.listLang.classList.add('is-active');
                return;
            }
            this.DOM.btn.classList.remove('is-active');
            this.DOM.listLang.classList.remove('is-active');
        });
    
        this.DOM.listLang.querySelectorAll('li').forEach(li => {
            li.addEventListener('click', () => {
                this.DOM.listLang.classList.remove('is-active');
            });
        });
    }

}