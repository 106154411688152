class Home {

    static init() {
        new Home();
    }

    constructor() {
        console.log('Page home');
    }
    
}

export default {
    init() {
        Home.init();
    }
}