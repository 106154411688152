const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default class FilterProduct {
    static init() {
        //new FilterProduct();
    }

    constructor() {
        this.DOM = {};
        this.DOM.modal = document.querySelector('.modal-filter'),
        this.DOM.btnFilter = document.querySelector('.btn-filter-products');
        //this.DOM.btnClose = this.DOM.modal.querySelector('.js-modal-btn-close');
        this.DOM.modalContent = this.DOM.modal.querySelector('.content');
        this.DOM.filters = this.DOM.modal.querySelector('.filters');
        this.bindEvents();
    }

    open() {
        if (!this.DOM.modal.classList.contains('is-open')) {
            this.DOM.modal.classList.add('is-open');
            disableBodyScroll(this.DOM.modal);
        }   
    }

    close() {
        if (this.DOM.modal.classList.contains('is-open')) {
            this.DOM.modal.classList.remove('is-open');
            enableBodyScroll(this.DOM.modal);
        }
    }

    filtering(filter) {
        filter.addEventListener('click', () => {
            if (!filter.classList.contains('is-open')) { 
                filter.classList.add('is-open');
                return;
            }
            filter.classList.remove('is-open');
        });
    }

    bindEvents() {
        this.DOM.btnFilter.addEventListener('click', () => this.open());
        this.DOM.modal.addEventListener('click', () => this.close());
        this.DOM.btnClose.addEventListener('click', () => this.close());
        this.DOM.modalContent.addEventListener('click', e => e.stopPropagation());
        this.DOM.filters.querySelectorAll('li').forEach(filter => this.filtering(filter));
    }
}